<template>
  <div>
    <div class="row">
      <div class="col-12 mt-xl-5">


          <div class="row">
            <div class="col-12 col-md-6 m-auto p-lg-2 p-xl-4">
              <Form @submit="login" :validation-schema="schemaLogin">
                <div class="row">
                  <div class="col-12 m-auto text-center">
                    <div class="logo-container m-auto pb-3" style="width: 100px; max-width: 100%">
                      <img src="./../../assets/images/logo/logo_white.png" alt="" class="m-auto"/>
                    </div>
                  </div>

                  <div v-if="errorLogin" class="col-12 text-danger text-center">{{ errorLogin }}</div>

                  <div class="col-12">
                    <vee-input
                            name="emailLogin"
                            type="text"
                            inputClasses="no-border white form-control-lg"
                            placeholder="Email"
                            addon-left-icon="now-ui-icons ui-1_email-85"
                    />
                  </div>
                  <div class="col-12">
                    <vee-input
                            name="passwordLogin"
                            type="password"
                            inputClasses="no-border white form-control-lg"
                            placeholder="Password"
                            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                    />
                  </div>


                  <div class="col-12 text-center">
                    <n-button native-type="submit" type="primary" round block :disabled="buttonDisabled">
                      {{ $t('login.login') }}
                    </n-button>

                    <a class="link footer-link text-center text-primary" style="cursor: pointer; text-transform: uppercase; font-size: 15px;" v-on:click="resetPassword">{{ $t('login.reset_pass') }}</a>

                  </div>

                </div>
              </Form>
            </div>





            <div class="col-12 col-md-6 mt-5 mt-lg-auto">
              <img src="./../../assets/images/login.png" alt="" class="m-auto"/>
            </div>


          </div>

      </div>


    </div>
  </div>
</template>

<script>
import { VeeCheckbox, VeeInput } from "src/components";
import { Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "register-page",
  components: {
    VeeCheckbox,
    VeeInput,
    Form,
  },
  data() {


    return {
      error: null,
      errorLogin: null,
      buttonDisabled: false,
      saving: false,
      terms_url: process.env.VUE_APP_TERMS_URL,
      privacy_policy_url: process.env.VUE_APP_PRIVACY_POLICY_URL,
    };
  },
  methods: {
    async register(values, actions) {
      // set single field value
      // actions.setFieldValue('email', 'ummm@example.com');
      // set multiple values
      // actions.setValues({
      //   email: 'ummm@example.com',
      //   password: 'P@$$w0Rd',
      // });

      this.error = "";
      this.saving = true;
      this.buttonDisabled = true;

      let token = await this.recaptcha("registration");

      axios.post(process.env.VUE_APP_SERVER_BASE_URL + "users", {
          email: values.email,
          plainPassword: values.password,
          language: this.$store.getters.getLang,
          captchaToken: token
      })
      .then((response) => {
        actions.resetForm({});

        Swal.fire({
          icon: 'success',
          title: this.$i18n.t("registration.email_sent"),
          html: this.$i18n.t("registration.email_sent_message"),
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false
        }).then((result) => {
          axios.post(process.env.VUE_APP_SERVER_BASE_URL + "login_check", {
            email: values.email,
            password: values.password,
          }).then((response) => {
            this.$store.commit('auth_success', response.data.user);
            this.$router.push(this.$route.query.redirect || '/dashboard');
          }).catch((error) => {
            this.$router.push("/login");
            console.log(error);
          });
        });
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t("error." + stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message)
        {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        console.log(error);
      }).finally(() => {this.buttonDisabled = false; this.saving = false;});
    },
    async recaptcha(actionName) {
        await this.$recaptchaLoaded();
        return await this.$recaptcha(actionName);
    },
    async login(values, actions) {
      this.buttonDisabled = true;
      this.errorLogin = null;

      let captchaToken = await this.recaptcha("login");

      axios.post(process.env.VUE_APP_SERVER_BASE_URL + "login_check", {
          email: values.emailLogin,
          password: values.passwordLogin,
          captchaToken: captchaToken
      }).then((response) => {

          this.$store.commit('auth_success', response.data.user);
          this.$router.push(this.$route.query.redirect || '/dashboard');

      }).catch((error) => {
          if (error.response && error.response.data && error.response.data.violations &&
              error.response.data.violations.length > 0) {
              for (let i = 0; i < error.response.data.violations.length; i++) {
                  let stringErr = error.response.data.violations[i].message;
                  if(stringErr.endsWith("."))
                      stringErr = stringErr.slice(0, -1);
                  this.errorLogin += this.$i18n.t("error." + stringErr) + ". ";
              }
          } else if(error.response && error.response.data && error.response.data.message) {
              let stringErr = error.response.data.message;
              if(stringErr.endsWith("."))
                  stringErr = stringErr.slice(0, -1);
              this.errorLogin = this.$i18n.t("error." + stringErr);
          }
          else this.errorLogin = this.$i18n.t("error.try_again");
          console.log(error);
      }).finally(() => {this.buttonDisabled = false;});
    },
    resetPassword() {
        Swal.fire({
            title: this.$i18n.t('login.reset_pass'),
            input: "email",
            inputValue: "",
            inputPlaceholder: "E-mail",
            padding: "20px",
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('login.reset'),
            showLoaderOnConfirm: true,
            preConfirm: async (email) => {
                let captchaToken = await this.recaptcha("reset_pass");
                return axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'reset_password_request/', {email: email, captchaToken: captchaToken})
                    .then(response => {
                        return undefined;
                    }).catch(error => {
                        Swal.showValidationMessage(this.$i18n.t(error.response.data.message));
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
            backdrop: true
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    html: this.$i18n.t('login.reset_request_sent'),
                });
            }
        });
    }
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required().min(8),
      confirm: Yup.string().required().oneOf([Yup.ref("password")], {key: "yup.passwords_not_match", values: {}}),
      privacy: Yup.boolean().oneOf([true], "yup.checkbox_required"),
      terms: Yup.boolean().oneOf([true], "yup.checkbox_required"),
    });

    const schemaLogin = Yup.object().shape({
          emailLogin: Yup.string().email().required(),
          passwordLogin: Yup.string().required(),
      });

    return {
      schema,
      schemaLogin
    };
  },
};
</script>
<style>
  .form-group.no-border.white > .form-control::placeholder {
    color: white;
  }
  .form-group.no-border.white > .form-control::-webkit-input-placeholder { /* Edge */
    color: white;
  }

  .form-group.no-border.white > .form-control:-ms-input-placeholder { /* Internet Explorer */
    color: white;
  }

  .form-group.no-border.white i {
    color: white;
  }
</style>
