import { createRouter, createWebHistory } from "vue-router";
import NotFoundPage from "src/pages/NotFoundPage";
import Registration from "src/pages/Frontend/Registration.vue";
import Login from "src/pages/Frontend/LogIn.vue";
import AuthLayout from "src/pages/AuthLayout";
import store from "src/store";
import axios from "axios";
import ContactUs from "src/pages/Frontend/ContactUs";
import ConfirmRegistration from "src/pages/Frontend/ConfirmRegistration";
import ResetPassword from "src/pages/Frontend/ResetPassword";
import BackLayout from "src/pages/Backend/BackLayout";
import NewSubscription from "src/pages/Backend/completeRegistration/NewSubscription";
import Form from "src/pages/Backend/completeRegistration/Form";
import EmailVerification from "src/pages/Backend/completeRegistration/EmaiVerification";
import Dashboard from "src/pages/Backend/Dashboard/Dashboard";
import TermsAndConditions from "src/pages/Frontend/TermsAndConditions";
import PrivacyPolicy from "src/pages/Frontend/PrivacyPolicy";
import CookiePolicy from "src/pages/Frontend/CookiePolicy";
import {Role} from "src/role";
import UsersStats from "src/pages/Backend/manager/UsersStats";
import ManageUser from "src/pages/Backend/manager/ManageUser";
import ManageDocuments from "src/pages/Backend/manager/ManageDocuments";
// import Documents from "src/pages/Backend/Documents/Documents";
import ManageFailedSubscriptions from "src/pages/Backend/manager/ManageFailedSubscriptions";
import UserSubscriptionHistory from "src/pages/Backend/manager/UserSubscriptionHistory";
import RevenueReport from "src/pages/Backend/manager/RevenueReport";
import ManagePublicDocuments from "src/pages/Backend/manager/ManagePublicDocuments";
import WaitingSubscriptionPayment from "src/pages/Backend/completeRegistration/WaitingSubscriptionPayment";
import BookConsultancy from "src/pages/Frontend/BookConsultancy";
import BookConsultancySuccess from "src/pages/Frontend/BookConsultancySuccess";
import DownloadInvoices from "src/pages/Backend/manager/DownloadInvoices";
import NewCustomInvoice from "src/pages/Backend/manager/NewCustomInvoice";
import ManageProducts from "src/pages/Backend/manager/ManageProducts";
import Invoices from "src/pages/Backend/payments/Invoices";
import BuyProduct from "src/pages/Frontend/BuyProduct";
import BuyProductSuccess from "src/pages/Frontend/BuyProductSuccess";
import ManageUserSubscriptions from "src/pages/Backend/manager/ManageUserSubscriptions";
import ListUnincorporated from "src/pages/Backend/manager/ListUnincorporated";
import NewCreditNote from "src/pages/Backend/manager/NewCreditNote";
// import CustomerDocuments from "src/pages/Backend/Documents/CustomerDocuments";
// import NewBankConnection from "src/pages/Backend/BankConnection/NewBankConnection";
import ManageCustomerBanks from "src/pages/Backend/manager/ManageCustomerBanks";
import NewProForma from "src/pages/Backend/manager/NewProForma";
import DownloadProForma from "src/pages/Backend/manager/DownloadProForma";
import ManageCompanyHouseData from "src/pages/Backend/manager/ManageCompanyHouseData";
import ListCompanies from "src/pages/Backend/manager/ListCompanies";
import FinancialStatement from "src/pages/Backend/companyHouse/FinancialStatement";
import RoyaltiesCalculator from "src/pages/Backend/companyHouse/RoyaltiesCalculator";
// import UploadNew from "src/pages/Backend/Documents/UploadNew";
import UploadInvoiceSent from "src/pages/Backend/Documents/UploadInvoiceSent";
import UploadInvoiceReceived from "src/pages/Backend/Documents/UploadInvoiceReceived";
import UploadBankStatement from "src/pages/Backend/Documents/UploadBankStatement";
import UploadResolutions from "src/pages/Backend/Documents/UploadResolutions";
import UploadCompanyHouse from "src/pages/Backend/Documents/UploadCompanyHouse";
import UploadShareholders from "src/pages/Backend/Documents/UploadShareholders";
import AgreementTemplates from "src/pages/Backend/Documents/AgreementTemplates";
import ResolutionTemplates from "src/pages/Backend/Documents/ResolutionTemplates";
import Archive from "src/pages/Backend/Documents/Archive";
import CompanyRoyalties from "src/pages/Backend/manager/CompanyRoyalties";
import SignDocument from "src/pages/Backend/Documents/SignDocument";
import ToSignList from "src/pages/Backend/Documents/ToSignList";
import ClientPage from "src/pages/Backend/manager/ClientPage";



let frontend = {
  path: "/",
  component: AuthLayout,
  name: "Layout",
  redirect: "/login",
  meta: {
    requiresAuth: false,
  },
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/register",
      name: "Register",
      redirect: "/signup",
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/signup",
      name: "SignUp",
      component: Registration,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/contact_us",
      name: "ContactUs",
      component: ContactUs,
      meta: {
        requiresAuth: false,
      },
    },
    // {
    //   path: "/terms",
    //   name: "TermsAndConditions",
    //   component: TermsAndConditions,
    //   meta: { requiresAuth: false },
    // },
    // {
    //   path: "/privacy_policy",
    //   name: "PrivacyPolicy",
    //   component: PrivacyPolicy,
    //   meta: { requiresAuth: false },
    // },
    // {
    //   path: "/cookie_policy",
    //   name: "CookiePolicy",
    //   component: CookiePolicy,
    //   meta: { requiresAuth: false },
    // },
    {
      path: '/confirm_registration/:userId/:registrationString',
      name: 'confirm_registration',
      component: ConfirmRegistration,
      meta: { requiresAuth: false }
    },
    {
      path: '/reset_password/:userId/:registrationString',
      name: 'reset_password',
      component: ResetPassword,
      meta: { requiresAuth: false }
    },
    {
      path: "/book_consultancy",
      name: "BookConsultancy",
      component: BookConsultancy,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/book_consultancy_success",
      name: "BookConsultancySuccess",
      component: BookConsultancySuccess,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/buy/:productId",
      name: "BuyProduct",
      component: BuyProduct,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/buy_success",
      name: "BuyProductSuccess",
      component: BuyProductSuccess,
      meta: {
        requiresAuth: false,
      },
    },
  ],
};


let dashboard = {
  path: "/dashboard",
  component: BackLayout,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/invoices",
      name: "Invoices",
      component: Invoices,
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //   path: "/new_bank_connection/:bank_id?",
    //   name: "NewBankConnection",
    //   component: NewBankConnection,
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    {
      path: "/financial-statement",
      name: "FinancialStatement",
      component: FinancialStatement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/royalties",
      name: "RoyaltiesCalculator",
      component: RoyaltiesCalculator,
      meta: {
        requiresAuth: true,
      },
    },
  ]
};


let docs = {
  path: "/documents",
  component: BackLayout,
  meta: {
    requiresAuth: true,
  },
  children: [
    // {
    //   path: "/documents/received",
    //   name: "Documents",
    //   component: Documents,
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    // {
    //   path: "/documents/uploaded",
    //   name: "CustomerDocuments",
    //   component: CustomerDocuments,
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    // {
    //   path: "/documents/new",
    //   name: "UploadNewDocument",
    //   component: UploadNew,
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },

    {
      path: "/documents/archive",
      name: "Archive",
      component: Archive,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/documents/invoiceSent",
      name: "UploadInvoiceSent",
      component: UploadInvoiceSent,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/documents/invoiceReceived",
      name: "UploadInvoiceReceived",
      component: UploadInvoiceReceived,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/documents/bankStatement",
      name: "UploadBankStatement",
      component: UploadBankStatement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/documents/resolutions",
      name: "UploadResolutions",
      component: UploadResolutions,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/documents/companyHouse",
      name: "UploadCompanyHouse",
      component: UploadCompanyHouse,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/documents/shareholders",
      name: "UploadShareholders",
      component: UploadShareholders,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/documents/agreement",
      name: "AgreementTemplates",
      component: AgreementTemplates,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/documents/resolution",
      name: "ResolutionTemplates",
      component: ResolutionTemplates,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/documents/to_sign",
      name: "DocumentsToSign",
      component: ToSignList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/documents/sign_document/:id",
      name: "SignDocument",
      component: SignDocument,
      meta: {
        requiresAuth: true,
      },
    },
]};


let completeRegistration = {
  path: "/complete_registration",
  component: BackLayout,
  redirect: "/complete_registration/subscription",
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "subscription/:status?",
      name: "Subscription",
      component: NewSubscription,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "waiting_payment",
      name: "WaitingPayment",
      component: WaitingSubscriptionPayment,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "email_verification",
      name: "emailVerification",
      component: EmailVerification,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "form",
      name: "NewCompany",
      component: Form,
      meta: {
        requiresAuth: true,
      },
    },
  ]
};

let manager = {
  path: "/manager",
  component: BackLayout,
  meta: {
    requiresAuth: true,
    requiresRole: Role.Manager
  },
  redirect: "/manager/users_stats",
  children: [
    {
      path: "users_stats",
      name: "UsersStats",
      component: UsersStats,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "handle_user/:id",
      name: "HandleUser",
      component: ManageUser,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "manage_subscriptions",
      name: "ManageFailedSubscriptions",
      component: ManageFailedSubscriptions,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "handle_documents/:userid",
      name: "HandleDocuments",
      component: ManageDocuments,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "manage_public_documents",
      name: "ManagePublicDocuments",
      component: ManagePublicDocuments,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "manage_user_subs/:userid",
      name: "ManageUserSubs",
      component: ManageUserSubscriptions,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "sub_history/:userid",
      name: "UserSubscriptionHistory",
      component: UserSubscriptionHistory,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "revenue_report",
      name: "RevenueReport",
      component: RevenueReport,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "manage_products",
      name: "manageProducts",
      component: ManageProducts,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "download_invoices",
      name: "DownloadInvoices",
      component: DownloadInvoices,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "new_invoice/:id?",
      name: "NewCustomInvoice",
      component: NewCustomInvoice,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "new_credit_note/:inv_id?",
      name: "NewCreditNote",
      component: NewCreditNote,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "unincorporated",
      name: "ListUnincorporated",
      component: ListUnincorporated,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "manage_customer_banks/:userId",
      name: "ManageCustomerBanks",
      component: ManageCustomerBanks,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "new_pro_forma/:id?",
      name: "NewProForma",
      component: NewProForma,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "download_pro_forma",
      name: "DownloadProForma",
      component: DownloadProForma,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "handle_company_house/:user_id",
      name: "HandleCompanyHouse",
      component: ManageCompanyHouseData,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "list_companies/",
      name: "ListCompanies",
      component: ListCompanies,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "company_royalties/:customer_id",
      name: "CompanyRoyalties",
      component: CompanyRoyalties,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
    {
      path: "customer_page/:customer_id",
      name: "ClientPage",
      component: ClientPage,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Manager
      },
    },
  ]
};


const routes = [
  frontend,
  dashboard,
  completeRegistration,
  docs,
  manager,
  { path: '/:pathMatch(.*)*', name: "NotFound", component: AuthLayout, children: [{path: "", component: NotFoundPage, name: "NotFound"}] },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach(async (to, from) => {
  // in this way, extract the variable requireRole from to.meta, not all the attributes of to.meta!
  const { requiresRole } = to.meta;
  let loggedUser = store.getters.isLoggedIn;

  // name of the routes to avoid if the user is already logged in
  // note: confirm_registration can be accessed also if the user is loggedIn.
  // let listSpecialPaths = ["Register", "Login", "TwoFA", "decline_registration", "restore_account"];
  let listSpecialPaths = ["Register", "Login", "SignUp", "reset_password"];

  if (!to.meta.requiresAuth && !listSpecialPaths.includes(to.name))
    return true;

  if (!loggedUser) {
    await axios
      .post(process.env.VUE_APP_SERVER_BASE_URL + "me/", {})
      .then((response) => {
        store.commit("auth_success", response.data.user);
        loggedUser = true;
      })
      .catch((error) => {});
  }

  if (!loggedUser && !to.meta.requiresAuth) return true;

  // redirect to login page if not logged in and trying to access a restricted page
  if (!loggedUser && to.meta.requiresAuth)
    return { path: "/login", query: { redirect: to.path } };

  // redirect to dashboard if the user is logged in and is trying to access to a "special path".
  if (listSpecialPaths.includes(to.name) && loggedUser)
      return "/dashboard";


  // if we need an array of roles to check, use this:
  // let checker = (arr, target) => target.every(v => arr.includes(v));

  // check if route is restricted by role
  if (requiresRole && !store.getters.hasRole(requiresRole))
    return false; // role not authorized

  // redirect to initial registration process if new user and the user is not a manager or admin
  if(!store.getters.hasRole(Role.Admin) && !store.getters.hasRole(Role.Manager)) {
    // Stripe success url
    if (to.name === "WaitingPayment")
      return true;

    // Check if the last subscription has been paid and if the user is not created by system (new user).
    let hasPaidSubscription = store.getters.hasPaidSubscription;

    let emailVerified = store.getters.isUserEmailVerified;
    let hasCompany = store.getters.hasCompany;
    if (!hasCompany) {
      if (!hasPaidSubscription)
        if (to.name === "Subscription")
          return true;
        else
          return "/complete_registration/subscription";

      if (!emailVerified)
        if (to.name === "emailVerification")
          return true;
        else
          return "/complete_registration/email_verification";

      if (to.name === "NewCompany")
        return true;
      else
        return "/complete_registration/form";
    }
  }

  return true;
});

export default router;
