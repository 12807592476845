<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Companies</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
                <el-input
                        placeholder="search by company name"
                        v-model="filter.name"
                        name="filter_name"
                        type="text"
                        clearable>
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
                <el-input
                        placeholder="search by customer email"
                        v-model="filter.customerEmail"
                        name="filter_email"
                        type="email"
                        clearable>
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
                <el-input
                        placeholder="search by company number"
                        v-model="filter.number"
                        name="filter_number"
                        type="text"
                        clearable>
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-2 text-center m-auto">
                <n-button native-type="button" type="primary" class="btn mt-0" @click="loadCompanies(pageSize, 1)" :disabled="loading || saving">Search</n-button>
                <n-button native-type="button" type="default" class="btn mt-0" @click="clearSearch()" :disabled="loading || saving">Clear search</n-button>
              </div>


              <div class="col-12 text-center font-weight-bold">
                <span v-if="loading" class="text-danger d-block"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
              </div>



              <div class="col-12 mt-5">


                <el-table class="table-striped" :data="companies" border style="width: 100%">

                  <el-table-column key="name" :min-width="100" prop="name" label="Name">
                    <template #default="scope">
                      <router-link :to="'/manager/customer_page/' + scope.row.compiler.id" target="_blank">
                        <p class="m-0" style="font-size: 16px"> {{scope.row.name}} </p>
                      </router-link>
                      <p v-if="scope.row.companyNumber" class="m-0">CN: {{ scope.row.companyNumber }}</p>
                      <p v-if="scope.row.vatNumber" class="m-0">VAT: {{ scope.row.vatNumber }}</p>
                    </template>
                  </el-table-column>

<!--                  <el-table-column key="compiler" :min-width="100" prop="compiler" label="E-mail">-->
<!--                    <template #default="scope">-->
<!--                      <span v-if="scope.row.compiler">{{scope.row.compiler.email}}</span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->


                  <el-table-column key="status" :min-width="90" prop="status" label="Status">
                    <template #default="scope">
                      <span v-if="scope.row.companyHouse">{{ scope.row.companyHouse.companyStatus }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column key="accountNextMadeUpTo" :min-width="90" prop="accountNextMadeUpTo" label="Account Up to" sortable="custom" :sort-change="sortBy('account')">
                    <template #default="scope">
                      <p v-if="scope.row.companyHouse" class="m-0">{{ formatDate(scope.row.companyHouse.accountNextMadeUpTo, false) }}</p>
                    </template>
                  </el-table-column>

                  <el-table-column key="accountNextDue" :min-width="90" prop="accountNextDue" label="Account Exp Date" sortable="custom" :sort-change="sortBy('account')">
                    <template #default="scope">
                      <p v-if="scope.row.companyHouse" class="m-0"
                         :class="new Date(scope.row.companyHouse.accountNextDue) <= new Date() ? 'text-danger font-weight-bold' : ''">
                        {{ formatDate(scope.row.companyHouse.accountNextDue, false) }}</p>
                    </template>
                  </el-table-column>


                  <el-table-column key="confirmationStatementNextMadeUpTo" :min-width="90" prop="confirmationStatementNextMadeUpTo" label="Conf. Statement Up To">
                    <template #default="scope">
                      <p v-if="scope.row.companyHouse" class="m-0">{{ formatDate(scope.row.companyHouse.confirmationStatementNextMadeUpTo, false) }}</p>
                    </template>
                  </el-table-column>


                  <el-table-column key="status" :min-width="90" prop="confirmationStatement" label="Conf. Statement Exp Date">
                    <template #default="scope">
                      <p v-if="scope.row.companyHouse" class="m-0"
                         :class="new Date(scope.row.companyHouse.confirmationStatementNextDue) <= new Date() ? 'text-danger font-weight-bold' : ''">
                        Due by {{ formatDate(scope.row.companyHouse.confirmationStatementNextDue, false) }}
                      </p>
                    </template>
                  </el-table-column>

<!--                  <el-table-column key="status" :min-width="90" prop="lastUpdate" label="Updated on">-->
<!--                    <template #default="scope">-->
<!--                      <span v-if="scope.row.companyHouse">{{ formatDate(scope.row.companyHouse.updateDate, false) }}</span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->

                  <el-table-column key="actions" :min-width="120" class-name="text-center" :label="$t('common.actions')">
                    <template #default="scope">

                      <el-select class="select-default select-" placeholder="actions.." model-value="" size="small">
                        <el-option class="select-default" key="10" label="" value="">
                          <router-link :to="'/manager/customer_page/' + scope.row.compiler.id" v-if="scope.row.compiler" target="_blank">
                            <p> <i class="now-ui-icons arrows-1_refresh-69"></i> Customer Page</p>
                          </router-link>
                        </el-option>
                        <el-option class="select-default" key="10" label="" value="">
                          <router-link :to="'/manager/handle_company_house/' + scope.row.compiler.id" v-if="scope.row.compiler" target="_blank">
                            <p> <i class="now-ui-icons business_bank"></i> Financial Statement</p>
                          </router-link>
                        </el-option>
                        <el-option class="select-default" key="10" label="" value="">
                          <router-link :to="'/manager/company_royalties/' + scope.row.compiler.id" v-if="scope.row.compiler" target="_blank">
                            <p> <i class="now-ui-icons business_chart-pie-36"></i> Royalties</p>
                          </router-link>
                        </el-option>

                        <el-option class="select-default" key="10" label="" value="">
                          <router-link :to="'/manager/handle_documents/' + scope.row.compiler.id" v-if="scope.row.compiler" target="_blank">
                            <p> <i class="now-ui-icons files_box"></i> Documents</p>
                          </router-link>
                        </el-option>

                        <el-option class="select-default" key="10" label="" value="">
                          <router-link :to="'/manager/manage_user_subs/' + scope.row.compiler.id" v-if="scope.row.compiler" target="_blank">
                            <p> <i class="now-ui-icons location_compass-05"></i> Manage Subscriptions</p>
                          </router-link>
                        </el-option>

                      </el-select>


                    </template>
                  </el-table-column>

                  <template #empty="">
                    <span>{{ $t('common.no_data') }}</span>
                  </template>

                </el-table>

              </div>

              <div class="col-12">
                <div class="row">
                  <div class="order-0 order-lg-0 col-6 col-lg-3">
                    <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                    <el-select class="select-default" v-model="pageSize" value="" label=""
                               v-on:change="handlePageSizeChange">
                      <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                      <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                      <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                      <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                    </el-select>
                  </div>

                  <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                    <el-pagination
                            class="pt-3"
                            v-model:currentPage="currentPageNumber"
                            v-model:page-size="pageSize"
                            :page-sizes="[10,20,50,100]"
                            :pager-count="11"
                            :small="true"
                            :disabled="loading || saving"
                            layout="prev, pager, next"
                            :total="totalItems"
                            @current-change="handlePageChange"
                    >
                    </el-pagination>
                  </div>

                  <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                    <p>{{ $t('document.total') }}: {{ totalItems }}</p>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
    import axios from 'axios';
    import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker, ElPagination } from "element-plus";


    export default {
        name: "ListCompanies",
        components: {
            [ElSelect.name]: ElSelect,
            [ElOption.name]: ElOption,
            [ElInput.name]: ElInput,
            [ElTable.name]: ElTable,
            [ElTableColumn.name]: ElTableColumn,
            [ElDatePicker.name]: ElDatePicker,
            [ElPagination.name]: ElPagination
        },
        data () {
            return {
                loading: true,
                saving: false,
                error: null,
                companies: [],
                totalItems: 0,
                currentPageNumber: 1,
                pageSize: 20,
                filter: {name: null, customerEmail: null, number: null},
            }
        },
        methods: {
            showError(error) {
                this.error = "";
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.error = this.$i18n.t("error.login_expired");
                    this.$store.commit('logout');
                    this.$router.push('/login');
                }
                else if (error.response && error.response.data && error.response.data.violations &&
                    error.response.data.violations.length > 0) {
                    for (let i = 0; i < error.response.data.violations.length; i++)
                    {
                        let stringErr = error.response.data.violations[i].message;
                        if(stringErr.endsWith("."))
                            stringErr = stringErr.slice(0, -1);
                        this.error += this.$i18n.t(stringErr) + ". ";
                    }
                } else if(error.response && error.response.data && error.response.data.message)
                {
                    let stringErr = error.response.data.message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error = this.$i18n.t("error." + stringErr);
                }
                else this.error = this.$i18n.t("error.try_again");
                ElMessage.error(this.error);
                console.log(error);
            },
            disabledDate(time) {
                return time.getTime() > (new Date());
            },
            formatDate(date, time = true) {
                var dateObj = new Date(date);
                if(time)
                    return dateObj.toLocaleString();
                else
                    return dateObj.toLocaleDateString();
            },
            formatPrice(price)
            {
                return "€ " + (price/100).toFixed(2);
            },
            handlePageSizeChange () {
                this.loadCompanies(this.pageSize, this.currentPageNumber);
            },
            handlePageChange (val) {
                this.loadCompanies(this.pageSize, val);
            },
            clearSearch() {
                this.filter.name = null;
                this.filter.customerEmail = null;
                this.filter.number = null;
                this.loadCompanies(this.pageSize, 1);
            },
            loadCompanies(pageSize, page) {
                this.loading = true;
                this.error = null;
                // Necessary to let "refresh" the select components.
                this.companies = [];

                let filters = "";
                if (this.filter.name !== null && this.filter.name !== "")
                    filters += "&name=" + this.filter.name;
                if (this.filter.customerEmail !== null && this.filter.customerEmail !== "")
                    filters += "&compiler.email=" + this.filter.customerEmail;
                if (this.filter.number !== null && this.filter.number !== "")
                    filters += "&companyNumber=" + this.filter.number;

                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'companies?itemsPerPage=' + pageSize + '&page=' + page + filters)
                .then(response => {
                    this.companies = response.data["hydra:member"];
                    this.totalItems = response.data["hydra:totalItems"];
                    this.currentPageNumber = page;
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            sortBy(column) {
                //TODO: call loadCompanies adding sorting attribute. If it is not possible (company house attr.), create new custom controller
            }
        },
        computed: {
        },
        beforeMount() {
            this.loadCompanies(this.pageSize, this.currentPageNumber);
        },
    }
</script>

<style scoped>

</style>
