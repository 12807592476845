<template>
  <div>

    <div class="row">
      <div class="col-12 col-lg-8 m-auto">
        <div class="card">
          <div class="card-body">
            <div class="row pb-5">
              <div class="col-12">
                <h4 class="title">Financial Statement</h4>
                <p class="d-inline">Last synchronization: </p>
                <div v-if="!loading" class="d-inline">
                  <div v-if="chData" class="d-inline">
                    <p class="d-inline">{{ formatDate(chData.updateDate, true) }}</p>
                  </div>
                  <div v-else class="d-inline">
                    <p class="d-inline">Never</p>
                    <div v-if="!company" class="d-inline">
                      <p class="d-inline">No company data found.</p>
                    </div>
                    <div v-else class="d-inline">
                      <p class="d-inline">No data available yet.</p>
                    </div>
                  </div>
                </div>


                <p v-if="loading" class="m-1 text-center text-danger">{{$t('common.loading')}}...</p>
              </div>
            </div>


            <div class="row" v-if="chData && chData.companyStatus">
              <div class="col-12 text-center">
                <p>Company Status: <b>{{ chData.companyStatus }}</b></p>
              </div>
            </div>

            <div class="row pb-4">
              <div class="col-12 col-md-6">
                <h4>Accounts:</h4>
                <div v-if="!chData">
                  No data available yet.
                </div>
                <div v-else>
                  Next accounts made up to
                  <b>
                    <span v-if="chData.accountNextMadeUpTo">
                      {{ formatDate(chData.accountNextMadeUpTo, false) }}
                      </span>
                    <span v-else> - </span>
                  </b>

                  due by

                  <b>
                    <span v-if="chData.accountNextDue">
                      {{ formatDate(chData.accountNextDue, false) }}
                      </span>
                    <span v-else> - </span>
                  </b>

                  <br>
                  Last accounts made up to
                  <b>
                    <span v-if="chData.accountLastMadeUpTo">
                      {{ formatDate(chData.accountLastMadeUpTo, false) }}
                    </span>
                    <span v-else> - </span>
                  </b>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <h4>Confirmation statement:</h4>
                <div v-if="!chData">
                  No data available yet.
                </div>
                <div v-else>
                  Next statement date:
                  <b>
                    <span v-if="chData.confirmationStatementNextMadeUpTo">
                      {{ formatDate(chData.confirmationStatementNextMadeUpTo, false) }}
                      </span>
                    <span v-else> - </span>
                  </b>

                  due by

                  <b>
                    <span v-if="chData.confirmationStatementNextDue">
                      {{ formatDate(chData.confirmationStatementNextDue, false) }}
                      </span>
                    <span v-else> - </span>
                  </b>

                  <br>
                  Last statement dated
                  <b>
                    <span v-if="chData.confirmationStatementLastMadeUpTo">
                      {{ formatDate(chData.confirmationStatementLastMadeUpTo, false) }}
                      </span>
                    <span v-else> - </span>
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>





  </div>
</template>

<script>
  import axios from 'axios';
  import { ElMessage } from "element-plus";
  import Swal from "sweetalert2";


  export default {
    name: "CompanyHouseData",
    components: {
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        company: null,
        chData: null,
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
                return dateObj.toLocaleDateString();
        },
        disabledDate(time) {
            return time.getTime() > (new Date());
        },
        startDisabledDate(time) {
            return time.getTime() > this.endDate;
        },

    },
    computed: {
    },
    beforeMount() {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "companies?compiler=" + this.$store.getters.getUserId)
        .then(response => {
            this.company = response.data["hydra:member"][0];
            if(this.company.companyHouse) {
                // let companyHouseID = this.company.companyHouse.split("/");
                // companyHouseID = companyHouseID[companyHouseID.length-1];
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'company_houses/' +  this.company.companyHouse.id)
                .then(response => {
                    this.chData = response.data;
                }).catch(error => {this.showError(error);});
            }
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    },
  }
</script>

<style scoped>

</style>
