<template>
  <Form @submit="register" :validation-schema="schema">
    <div class="row">
      <div class="col-12 col-md-6 m-auto p-lg-2 p-xl-4">
        <vnud-card class="card-signup text-center" no-footer-line style="border-radius: 8px;">
          <template v-slot:header>
            <h4 class="card-title">{{ $t("registration.register") }}</h4>
          </template>

          <div v-if="error" class="text-danger text-center">{{ error }}</div>

          <vee-input
            name="email"
            type="text"
            placeholder="Email..."
            addon-left-icon="now-ui-icons ui-1_email-85"
          />

          <vee-input
            name="password"
            type="password"
            inputClasses="no-border form-control-lg"
            placeholder="Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />

          <vee-input
            name="confirm"
            type="password"
            inputClasses="no-border form-control-lg"
            :placeholder="$t('registration.confirm_pass')"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />

          <vee-checkbox class="text-left" name="privacy">
            <p class="m-0 p-0"><b class="text-danger">*</b> {{ $t('registration.accept_check') }}
<!--            <router-link to="/privacy_policy" target="_blank">Privacy Policy</router-link>.-->
            <a :href="privacy_policy_url" target="_blank"> Privacy Policy</a>.
            </p>
          </vee-checkbox>

          <vee-checkbox class="text-left" name="terms">
            <p class="m-0 p-0 d-inline"><b class="text-danger">*</b> {{ $t('registration.accept_check') }}
<!--              <router-link to="/terms" target="_blank">{{$t('common.terms_conditions')}}</router-link>.-->
              <a :href="terms_url" target="_blank"> {{ $t('common.terms_conditions') }}</a>.
            </p>
          </vee-checkbox>


          <template v-slot:footer>

            <n-button type="primary" native-type="submit" round :disabled="buttonDisabled">
              {{ $t('common.get_started') }}
            </n-button>

            <div v-if="saving" class="col-12 mt-2"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>
          </template>

        </vnud-card>
      </div>


      <div class="col-12 col-md-6 mt-5 m-auto">
        <img src="./../../assets/images/signup.png" alt="" class="m-auto d-block" style="width: 60%; height: auto;"/>
      </div>

    </div>
  </Form>
</template>

<script>
import { VeeCheckbox, VeeInput } from "src/components";
import { Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "register-page",
  components: {
    VeeCheckbox,
    VeeInput,
    Form,
  },
  data() {


    return {
      error: null,
      buttonDisabled: false,
      saving: false,
      terms_url: process.env.VUE_APP_TERMS_URL,
      privacy_policy_url: process.env.VUE_APP_PRIVACY_POLICY_URL,
    };
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded();
      return await this.$recaptcha('registration');
    },
    async register(values, actions) {
      // set single field value
      // actions.setFieldValue('email', 'ummm@example.com');
      // set multiple values
      // actions.setValues({
      //   email: 'ummm@example.com',
      //   password: 'P@$$w0Rd',
      // });

      this.error = "";
      this.saving = true;
      this.buttonDisabled = true;

      let token = await this.recaptcha();

      axios.post(process.env.VUE_APP_SERVER_BASE_URL + "users", {
          email: values.email,
          plainPassword: values.password,
          language: this.$store.getters.getLang,
          captchaToken: token
      })
      .then((response) => {
        actions.resetForm({});

        Swal.fire({
          icon: 'success',
          title: this.$i18n.t("registration.email_sent"),
          html: this.$i18n.t("registration.email_sent_message"),
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false
        }).then((result) => {
          axios.post(process.env.VUE_APP_SERVER_BASE_URL + "login_check", {
            email: values.email,
            password: values.password,
          }).then((response) => {
            this.$store.commit('auth_success', response.data.user);
            this.$router.push(this.$route.query.redirect || '/dashboard');
          }).catch((error) => {
            this.$router.push("/login");
            console.log(error);
          });
        });
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t("error." + stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message)
        {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        console.log(error);
      }).finally(() => {this.buttonDisabled = false; this.saving = false;});
    },
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required().min(8),
      confirm: Yup.string().required().oneOf([Yup.ref("password")], {key: "yup.passwords_not_match", values: {}}),
      privacy: Yup.boolean().oneOf([true], "yup.checkbox_required"),
      terms: Yup.boolean().oneOf([true], "yup.checkbox_required"),
    });

    return {
      schema,
    };
  },
};
</script>
<style></style>
