<template>
  <div>


    <div class="row">
      <div class="col-12 col-lg-8 m-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h4 class="title">{{ $t('document.please_sign') }}</h4>
                <p>{{ $t('document.sign_header') }}</p>
                <p v-if="loading" class="text-danger text-center font-weight-bold"><i class="fa fa-spinner fa-spin"></i> {{ $t("common.loading") }}...</p>
              </div>
            </div>

            <div class="row my-5" v-if="document">
              <div class="col-12 col-md-6 text-center">
                <p>{{ $t('document.document_dated') }} <br> <b>{{ formatDate(document.uploadDate, false) }}</b></p>
              </div>
              <div class="col-12 col-md-6">
                <p class="mb-0">{{ document.name }}</p>
                <n-button native-type="button" type="primary" class="btn"
                          :disabled="loading || saving"
                          v-on:click="downloadDocument(document.id, document.name)">
                  <i class="now-ui-icons arrows-1_cloud-download-93"></i> {{ $t('common.download') }} {{ $t('document.document') }}
                </n-button>
              </div>
              <div class="col-12 text-center">
                {{ document.description }}
              </div>
            </div>

            <hr/>

            <div class="row">
              <div class="col-12 col-md-8 col-lg-6 col-xl-4 m-auto">
                <label class="mb-2">{{ $t('document.director_full_name') }}<span v-if="directors.length > 0">
                  (<span v-for="(d, index) in directors" v-bind:key="index">{{d.name}} {{d.surname}}<span v-if="index < directors.length -1">, </span></span>)</span>:
                </label>

                <br>

                <el-input
                        v-model="form.name"
                        name="name"
                        type="text"
                        v-on:input="checkDirectorName">

                  <template #append>
                    <i v-if="validDirectorName === true" class="now-ui-icons ui-1_check text-success font-weight-bold"></i>
                    <i v-else class="now-ui-icons ui-1_simple-remove text-danger font-weight-bold"></i>
                  </template>

                </el-input>
              </div>

              <div class="col-12 col-lg-11 col-xl-10 m-auto pt-4 order-0 order-md-1">
                <el-checkbox v-model="form.acceptTerms"
                             class="d-flex">
                  {{ $t('document.sign_disclaimer') }}
                </el-checkbox>
              </div>

              <div class="col-12 col-md-4 col-lg-4 col-xl-3 m-auto pt-4 order-1 order-md-0">
                <n-button native-type="button" type="primary" class="d-block px-2"
                          :disabled="saving || loading"
                          v-on:click="signDocument">
                  {{ $t('document.sign') }}
                </n-button>
              </div>


              <div v-if="saving" class="col-12 text-center mt-3 order-3">
                <p class="text-center text-danger"><i class="fa fa-spinner fa-spin"></i> {{$t('common.saving')}}...</p>
              </div>

              <div class="col-12 text-center mt-3 order-2" v-if="form_error">
                <p class="text-center text-danger"> {{ form_error }}</p>
              </div>

            </div>

            <div class="row mt-5">

            </div>

          </div>
        </div>
      </div>
    </div>





  </div>
</template>

<script>
  import axios from 'axios';
  import { ElMessage, ElDatePicker, ElInput, ElSelect, ElOption, ElCheckbox } from "element-plus";
  import Swal from "sweetalert2";

  export default {
    name: "SignDocument",
    components: {
        [ElDatePicker.name]: ElDatePicker,
        [ElInput.name]: ElInput,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElCheckbox.name]: ElCheckbox,
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        form_error: null,
        document: null,
        directors: [],
        validDirectorName: false,
        form: {
            name: "",
            acceptTerms: false
        }
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
                return dateObj.toLocaleDateString();
        },
        disabledDate(time) {
            return time.getTime() > (new Date());
        },
        startDisabledDate(time) {
            if(this.balanceStart !== null)
                return time.getTime() < (new Date(this.balanceStart)) || time.getTime() > (new Date());
            else
                return time.getTime() > (new Date());
        },
        downloadDocument(docIri, nomeFile) {
            let id = docIri.split("/");
            id = id[id.length-1];
            let config = {
                method: 'get',
                url: process.env.VUE_APP_SERVER_BASE_URL + 'download-document/' + id,
                responseType: 'blob'
            };

            axios(config).then(response => {
                const blob = new Blob([response.data]);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = nomeFile;
                link.click();
                URL.revokeObjectURL(link.href);
            }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
        },
        checkDirectorName() {
          for (var d of this.directors) {
              var d_full_name = d.name.toLowerCase() + " " + d.surname.toLowerCase();
              var field = this.form.name.replace(/\s+/g,' ').trim().toLowerCase();
              this.validDirectorName = d_full_name === field;
          }
        },
        signDocument() {
            this.form_error = null;
            this.form.name = this.form.name.trim();
            if (this.form.name === "") {
                this.form_error = this.$t("document.name_missing");
                return;
            }

            if (!this.validDirectorName) {
                this.form_error = this.$t("document.wrong_director_name");
                return;
            }

            if (!this.form.acceptTerms) {
                this.form_error = this.$t("document.accept_terms_required");
                return;
            }

            this.saving = true;

            axios.post(process.env.VUE_APP_SERVER_BASE_URL + "documents/sign", {
                full_name: this.form.name,
                document_id: this.document.id })
            .then(response => {
                this.document = null;
                Swal.fire({
                    title: this.$t('document.signed'),
                    text: this.$t("document.signed_message") + this.$t("documents_folders." + response.data.folder) + ".",
                    icon: "success",
                    confirmButtonText: "Ok",
                }).then((result) => {
                    this.$router.push("/documents/" + response.data.folder);
                });
            }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});

        }
    },
    computed: {
    },
    beforeMount() {
        let fetchedId = this.$route.params.id;
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "documents/" + fetchedId)
        .then(response => {
            this.document = response.data;
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});


        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "companies/" + this.$store.getters.getCompanyId + "/directors/")
        .then(response => {
            this.directors = response.data.directors;
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    },
  }
</script>

<style scoped>

</style>
